<template>
  <div class="receivable-details">
    <v-card class="mb-2">
      <v-card-title> Laporan Piutang </v-card-title>

      <v-card-text v-text="branch.data.br_name"></v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="filter.dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Sisa Piutang </v-card-title>

      <v-card-text>
        <h2 v-text="summaries.remaining"></h2>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr class="header">
                <td>Tagihan Awal</td>
                <td v-text="summaries.total" class="text-right"></td>
              </tr>
              <tr>
                <td>Potongan Retur</td>
                <td v-text="summaries.returns" class="text-right"></td>
              </tr>
              <tr class="header">
                <td>Total Tagihan</td>
                <td v-text="summaries.afterReturns" class="text-right"></td>
              </tr>
              <tr>
                <td>Sudah Lunas</td>
                <td v-text="summaries.paymentDone" class="text-right"></td>
              </tr>
              <tr>
                <td>Dibayar Sebagian</td>
                <td v-text="summaries.paymentNotDone" class="text-right"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="fetchData"
      ></v-text-field>

      <v-data-table
        :headers="receivables.headers"
        :items="receivables.data"
        :loading="receivables.loading"
        :options.sync="options"
        :server-items-length="receivables.count"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'

export default {
  components: { DateRangePicker },

  name: 'InvoiceDetails',

  data() {
    return {
      filter: {
        dateRange: null,
      },

      receivables: {
        allocated: 0,
        count: 0,
        data: [],
        headers: [
          { text: 'No Transaksi', value: 'trans_no' },
          { text: 'Referensi', value: 'reference' },
          { text: 'Tanggal', value: 'tran_date' },
          { text: 'Total Tagihan', value: 'total' },
          { text: 'Potongan Retur', value: 'return_alloc' },
          { text: 'Sudah Lunas', value: 'payment_done_alloc' },
          { text: 'Dibayar Sebagian', value: 'payment_not_done_alloc' },
          { text: 'Sisa Piutang', value: 'remaining' },
        ],
        loading: false,
        paymentDoneAllocated: 0,
        paymentNotDoneAllocated: 0,
        remaining: 0,
        returnAllocated: 0,
        total: 0,
        totalAfterReturns: 0,
      },

      summaries: {
        loading: false,
        total: 0,
        returns: 0,
        afterReturns: 0,
        paymentDone: 0,
        paymentNotDone: 0,
        remaining: 0,
      },

      options: {},
      search: '',

      branch: {
        data: {},
        loading: false,
      },

      branchCode: null,
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.fetchData()
      },
    },

    dateRange() {
      this.fetchData()
    },
  },

  mounted() {
    this.branchCode = this.$route.params.branchCode

    this.getBranch()

    this.fetchSummaries()
  },

  methods: {
    getBranch() {
      this.branch.loading = false

      this.$http.get('/customers/' + this.branchCode).then((result) => {
        this.branch.data = result.data
        this.branch.loading = false
      })
    },

    fetchData() {
      if (!this.filter.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.receivables.data = []
      this.receivables.loading = true

      this.$http
        .get('/customers/' + this.branchCode + '/receivables', {
          params: {
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            branchCode: this.branchCode,
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
          },
        })
        .then(({ data }) => {
          this.receivables.allocated = data.allocated
          this.receivables.count = data.count

          data.result.forEach((payment) => {
            payment.tran_date = this.sql2date(payment.tran_date)
          })

          this.receivables.data = data.result
          this.receivables.paymentDoneAllocated = data.payment_done_allocated
          this.receivables.paymentNotDoneAllocated =
            data.payment_not_done_allocated
          this.receivables.returnAllocated = data.return_allocated
          this.receivables.total = data.total
          this.receivables.totalAfterReturns = data.total_after_returns
          this.receivables.remaining = data.remaining
          this.receivables.loading = false
        })
    },

    fetchSummaries() {
      this.summaries.loading = true

      this.$http
        .get('/customers/receivables/summaries', {
          params: {
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
            branch_code: this.branchCode,
          },
        })
        .then(({ data }) => {
          this.summaries.total = data.total
          this.summaries.returns = data.returns
          this.summaries.afterReturns = data.after_returns
          this.summaries.paymentDone = data.payments_done
          this.summaries.paymentNotDone = data.payments_not_done
          this.summaries.remaining = data.remaining
        })
    },

    refilter() {
      this.fetchSummaries()
      this.fetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.header td {
  background-color: #333333;
}
</style>
